import React, { useState, useEffect } from 'react';
import HeadLine from '../headLine';

import Tippy from '@tippyjs/react';
import Countdown_timer from '../Countdown_timer';
import Auctions_dropdown from '../dropdown/Auctions_dropdown';
import Link from 'next/link';
import { bidsModalShow } from '../../redux/counterSlice';
import Likes from '../likes';
// import { useDispatch } from 'react-redux';
import 'tippy.js/themes/light.css';
import Image from 'next/image';
import { database } from '../../firebase.config';
import { collection, getDocs } from 'firebase/firestore';


const Auctions_categories = () => {
  // const dispatch = useDispatch();
  const [loadMoreBtn, setLoadMoreBtn] = useState(true);
  const [artists, setArtists] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    const dbInstance = collection(database, 'artists');
    getDocs(dbInstance)
      .then(data => {
        if (!data.empty) {
          const result = data.docs.map((item) => {
            return {
              id: item.id,
              ...item.data()
            }
          })
          setList(result);
          setArtists(result.slice(0, 8))
        }
      })
  }, [])

  const handleloadMore = () => {
    setArtists(list)
    setLoadMoreBtn(false);
  };

  return (
    <div>
      <section className="py-24">
        <div className="container">
          <HeadLine
            image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png"
            text="Nuestros Artistas 🪗"
            classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
          />
          <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
            {artists.map((artist) => {
              const {
                images,
                username,
                name,
                rol,
                facebook,
                instagram,
                twitter,
                tiktok,
                website } = artist;
              return (
                <article key={username}>
                  <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                    <div className="mb-4 flex items-center justify-between relative">
                      <div className="flex -space-x-2 ">
                        <Tippy
                          theme="tomato"
                          content={<span className="py-1 px-2 block">Artista</span>}
                        >
                          <Link href={"/item/" + username}>
                            <a>
                              <Image
                                src={images[0].downloadURL}
                                alt="creator"
                                className="h-6 w-6 rounded-full"
                                height={24}
                                width={24}
                              />
                            </a>
                          </Link>
                        </Tippy>
                        <Tippy content={<span className="py-1 px-2 block">Owner: Sussygirl</span>}>
                          <Link href={"/item/" + username}>
                            <a>
                              <Image
                                src={images[0].downloadURL}
                                alt="creator"
                                className="h-6 w-6 rounded-full"
                                height={24}
                                width={24}
                              />
                            </a>
                          </Link>
                        </Tippy>
                      </div>

                      {/* auction dropdown */}
                      <Auctions_dropdown classes="dark:hover:bg-jacarta-600 dropdown hover:bg-jacarta-100 rounded-full " />
                    </div>
                    <figure className="relative">
                      <Link href={"/artist/" + username}>
                        <a>
                          <Image
                            src={images[0].downloadURL}
                            alt={name}
                            className="w-full rounded-[0.625rem]"
                            loading="lazy"
                            height="100%"
                            width="100%"
                            layout="responsive"
                            objectFit="cover"
                          />
                        </a>
                      </Link>
                      <Countdown_timer time={+20} />
                    </figure>
                    <div className="mt-7 flex items-center justify-between">
                      <Link href={"/artist/" + username}>
                        <a>
                          <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                            {name}
                          </span>
                        </a>
                      </Link>
                      <span className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap rounded-md border py-1 px-2">
                        {(() => {
                          switch (rol) {
                            case 'cantante':
                              return '🎤'
                            case 'acordeonista':
                              return '🪗'
                            case 'cajero':
                              return '🥁'
                            case 'guacharaquero':
                              return '🎼'
                            case 'pianista':
                              return '🎹'
                            case 'bajista':
                              return '🎸'
                            case 'corista':
                              return '🗣️'
                            default:
                              return '🎵'
                          }
                        })()}
                      </span>
                    </div>
                    <div className="mt-8 flex items-center justify-around">
                      {facebook && <span>
                        <a href={facebook} target="_blank" rel="noreferrer">
                          <svg className="h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-facebook" fill="#4267B2"></use>
                          </svg>
                        </a>
                      </span>}
                      {instagram && <span>
                        <a href={instagram} target="_blank" rel="noreferrer">
                          <svg className="h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-instagram" fill="#C13584"></use>
                          </svg>
                        </a>
                      </span>}
                      {twitter && <span>
                        <a href={twitter} target="_blank" rel="noreferrer">
                          <svg className="h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-twitter" fill="#1DA1F2"></use>
                          </svg>
                        </a>
                      </span>}
                      {tiktok && <span>
                        <a href={tiktok} target="_blank" rel="noreferrer">
                          <svg className="h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-tiktok"></use>
                          </svg>
                        </a>
                      </span>}
                      {website && <span>
                        <a href={website} target="_blank" rel="noreferrer">
                          🌐
                        </a>
                      </span>}
                    </div>

                    <div className="mt-8 flex items-center justify-between">
                      <button
                        className="text-accent font-display text-sm font-semibold"
                        onClick={() => dispatch(bidsModalShow())}
                      >
                        Place bid
                      </button>

                      <Likes like={10} classes="flex items-center space-x-1" />
                    </div>
                  </div>
                </article>
              );
            })}
          </div>

          {loadMoreBtn && (
            <div className="mt-10 text-center">
              <button
                onClick={handleloadMore}
                className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              >
                Ver más
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Auctions_categories;
