import { getApp, getApps, initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const app = !getApps().length ? initializeApp({
  apiKey: "AIzaSyD4s5DijCi9PLawibmAerf8cBo6Rn1Yf2Y",
  authDomain: "dinastia-vallenata.firebaseapp.com",
  projectId: "dinastia-vallenata",
  storageBucket: "dinastia-vallenata.appspot.com",
  messagingSenderId: "891005024381",
  appId: "1:891005024381:web:2e01164396d33175ef5914",
  measurementId: "G-M0468JWTJJ"
}) : getApp();
// export const analytics = getAnalytics(app);
export const database = getFirestore(app);
